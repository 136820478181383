import App from 'next/app'
import Head from 'next/head'
import React, { useEffect, useContext } from 'react'
import { createGlobalStyle } from 'styled-components'
import { Reset } from 'styled-reset'
import TempNav from 'components/TempNav'
import firebase from 'utils/firebase'
import {
  FirebaseProvider,
  UserProvider,
  useUser,
  useFirebase
} from '@sb-konzept/firebase-hooks'
import Router, { useRouter } from 'next/router'
import { MealProvider } from 'components/MealContext'
import ProgramContext, { ProgramProvider } from 'components/ProgramContext'
import { PhotoProvider } from 'components/PhotoContext'
import { SpotifyProvider } from 'components/SpotifyContext'
import { BadgeProvider } from 'components/BadgeContext'
import SubscriptionContext, {
  SubscriptionProvider
} from 'components/SubscriptionContext'
import useUserData from 'hooks/useUserData'
import Drawer from 'components/Drawer'
import * as Sentry from '@sentry/node'

const app = firebase()

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
  })
}

const GlobalStyle = createGlobalStyle`
.keyboard .hideOnKeyboard {
  display: none!important;
}
label {
  color: white;
  font-size: 16px;
  font-weight: bold;
}
.MuiSelect-select.MuiSelect-select.MuiSelect-select {
  padding-right: 0;
}
.MuiInput-underline.MuiInput-underline {
  :before {
    border-color: rgba(255, 255, 255, 0.42);
  }
  :after {
    border-color: white;
  }
  color: inherit;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 1rem;
}
h1 {
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
}
.swipeable-list {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.swipeable-list-item {
  position: relative;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow: hidden;
  width: 100%;
  z-index: 0;
}

.swipeable-list-item__content {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  display: flex;
}

.swipeable-list-item__content--return {
  transition: transform 0.5s ease-out;
}

.swipeable-list-item__content--remove {
  transition: transform 0.35s ease-in;
}

.swipeable-list-item__content-right {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  opacity: 0;
}

.swipeable-list-item__content-right--return {
  transition: opacity 0.5s ease-out;
}

.swipeable-list-item__content-left {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  opacity: 0;
  justify-content: flex-end;
}

.swipeable-list-item__content-left--return {
  transition: opacity 0.5s ease-out;
}
  .VictoryContainer {
    touch-action: auto !important;
    height: auto !important
  }
  .MuiCircularProgress-colorPrimary {
    && {
      color: #842008; 
    }
  }
  html {
    font-size: 14px;
  }
  @media (min-width: 960px) {
    html {
      font-size: 20px;
    }
  }
  body {
    user-select: none;
    color: ${props => (props.whiteColor ? 'white' : 'black')};
    font-family: open-sans;
    position: absolute;
    top: 0;
    width: 100vw;
    height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    padding-top: env(safe-area-inset-top);
    border-bottom: env(safe-area-inset-bottom) solid ${props =>
      props.greyBottom ? '#2c2c2c' : 'black'} ;
    /* border-bottom: env(safe-area-inset-bottom) solid #2c2c2c; */
    background-color: #000;
    color: #fff;
    overflow: hidden;
    > #__next {
      height: 100%;
      width: 100%;
    }
  } 
  h6 {
    font-weight: 700;
    padding-bottom: 4px;
  }
  h5 {
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
  }
  strong {
    font-weight: bold;
  }
  ol, ul {
    list-style-type: square;
  }
  hr {
    border-bottom: none;
    opacity: 0.63;
    width: 100%;
    margin: 1em 0;
  }
  .recipelists li{
    margin-left: 1.25rem;
  }
  .recipeorderedlists ol{
    list-style-type: decimal;
    line-height: 1.2rem;
  }
  .recipeorderedlists li{
    margin-left: 1.25rem;
  }
  .MuiTooltip-tooltip.MuiTooltip-tooltip {
    font-size: 1rem;
    background: white;
    color: black;  
  }
  em {
    font-style: italic;
  }`

const appsFlyerConfig = {
  ios: { devKey: 'P45odJu7HeYjWQxhULT7o3', appId: '1523032268' },
  android: { devKey: 'P45odJu7HeYjWQxhULT7o3' }
}

function RouteGuard({ children }) {
  const { subscribed, refresh, sentryLog } = useContext(SubscriptionContext)
  const { uid, value, authenticating, ref } = useUserData()
  const router = useRouter()
  const fb = useFirebase()
  useEffect(() => {
    document.addEventListener(
      'deviceready',
      () => {
        navigator.animatedSplashScreen?.hide()
        window.plugins.appsFlyer?.initSdk(
          appsFlyerConfig[cordova.platformId],
          null,
          console.log
        )
      },
      false
    )
    const onHide = () => document.body.classList.remove('keyboard')
    const onShow = () => document.body.classList.add('keyboard')
    window.addEventListener('keyboardWillShow', onShow)
    window.addEventListener('keyboardWillHide', onHide)
    return () => {
      window.removeEventListener('keyboardWillShow', onShow)
      window.removeEventListener('keyboardWillHide', onHide)
    }
  }, [])
  useEffect(() => {
    document.addEventListener(
      'deviceready',
      () => {
        if (
          typeof window !== 'undefined' &&
          !uid &&
          !window.localStorage.getItem('init')
        ) {
          window.localStorage.setItem('init', 'true')
          window?.cordova?.plugins?.notification?.local?.clearAll()
          window?.cordova?.plugins?.notification?.local?.schedule([
            {
              id: 1,
              smallIcon: 'res://ic_launcher',
              title: 'READY TO GET STARTED?',
              text: `Finish creating your account now to access your new workout plan today!`,
              trigger: { in: 3, unit: 'hour' }
            },
            {
              id: 2,
              smallIcon: 'res://ic_launcher',
              title: 'YOUR FREE TRIAL IS WAITING',
              text: `Finish creating your account now to access your new workout plan today!`,
              trigger: { in: 24, unit: 'hour' }
            },
            {
              id: 3,
              smallIcon: 'res://ic_launcher',
              title: "DON'T FORGET YOUR FREE TRIAL",
              text: `Finish creating your account now to access your new workout plan today!`,
              trigger: { in: 48, unit: 'hour' }
            }
          ])
        }
        if (
          typeof window !== 'undefined' &&
          uid &&
          !window.localStorage.getItem('account')
        ) {
          window.localStorage.setItem('account', 'true')
          window?.cordova?.plugins?.notification?.local?.clearAll()
          window?.cordova?.plugins?.notification?.local?.schedule([
            {
              id: 1,
              smallIcon: 'res://ic_launcher',
              title: "LET'S GET STARTED",
              text: `Start your Beastly app FREE trial!`,
              trigger: { in: 3, unit: 'hour' }
            },
            {
              id: 2,
              smallIcon: 'res://ic_launcher',
              title: 'YOUR FITNESS PROGRESS AWAITS',
              text: `Open the Beastly app and complete your first workout today!`,
              trigger: { in: 24, unit: 'hour' }
            },
            {
              id: 3,
              smallIcon: 'res://ic_launcher',
              title: 'IT’S TIME TO WORK OUT',
              text: `Open the Beastly app and complete your first workout today!`,
              trigger: { in: 48, unit: 'hour' }
            }
          ])
        }
      },
      false
    )
  }, [uid])
  useEffect(() => {
    window.router = router
    window.logInAs = u =>
      fetch(`/api/customLogin?uid=${u}`)
        .then(x => x.json())
        .then(({ token }) => fb.auth().signInWithCustomToken(token))
  }, [])
  useEffect(() => {
    if (ref && window.FirebasePlugin && value?.enablePush && window.cordova) {
      window.FirebasePlugin.grantPermission(() =>
        window.FirebasePlugin.onTokenRefresh(token =>
          ref.update({
            [`pushToken.${window.device.uuid}`]: token
          })
        )
      )
      if (window.cordova.platformId === 'ios') {
        window.FirebasePlugin.onTokenRefresh(token =>
          ref.update({
            [`pushToken.${window.device.uuid}`]: token
          })
        )
      }
    }
  }, [value?.enablePush, ref])

  useEffect(() => {
    if (!authenticating && !uid) {
      if (
        !['/signup-form', '/contact', '/forgot-password'].includes(
          router.pathname
        )
      )
        Router.push('/signup')
    } else if (
      !subscribed &&
      (router.pathname === '/mealplan' ||
        router.pathname === '/workout') /* && value?.freeWorkoutUsed */
    ) {
      try {
        refresh()
      } catch (e) {
        try {
          setTimeout(() => sentryLog(true), 2000)
        } catch (e) {}
      }
      Router.push('/subscription')
    } else if (
      value &&
      [
        'gender',
        'height',
        'weight',
        'level',
        'shape',
        'age',
        'experiencelevel',
        'system'
      ].reduce((acc, key) => acc || !value[key], false)
    ) {
      Router.push('/customize')
    } else if (value && !value.program && router.pathname !== '/customize') {
      Router.push('/experience')
    }
  }, [uid, authenticating, value, router?.pathname, subscribed])
  return authenticating ? <div /> : children
}

export default class MyApp extends App {
  render() {
    const { Component, pageProps, err, router } = this.props
    return (
      <FirebaseProvider app={app}>
        <UserProvider>
          <MealProvider>
            <ProgramProvider>
              <SpotifyProvider>
                <PhotoProvider>
                  <SubscriptionProvider>
                    <BadgeProvider>
                      <Head>
                        <meta charSet="utf-8" />
                        <meta
                          content="minimum-scale=1, maximum-scale=1, initial-scale=1, viewport-fit=cover"
                          name="viewport"
                        />
                        <meta content="#842008" name="theme-color" />
                        <link href="/manifest.json" rel="manifest" />
                      </Head>
                      <Reset />
                      <GlobalStyle greyBottom={router.pathname !== '/signup'} />
                      <RouteGuard>
                        <Drawer>
                          <Component {...pageProps} err={err} />
                        </Drawer>
                      </RouteGuard>
                    </BadgeProvider>
                  </SubscriptionProvider>
                </PhotoProvider>
              </SpotifyProvider>
            </ProgramProvider>
          </MealProvider>
        </UserProvider>
      </FirebaseProvider>
    )
  }
}
