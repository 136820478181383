import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import Link from 'next/link'
import BurgerIcon from 'images/burger.png'

const BurgerImage = styled.img`
  width: 20px;
  height: 24px;
`

const Navi = styled.div`
  z-index: 1;
  position: absolute;
  top: 30px;
  left: 10px;
  padding: 5px;
  background: #444;
  color: #fff;
  font-weight: 700;
  opacity: 0.1;
  cursor: pointer;
`

const NaviShow = styled.ul`
  z-index: 2;
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: fixed;
  top: 0px;
  width: 70%;
  padding: 20px;
  opacity: 1;
  border-radius: 0;
  background: #444;
  a {
    color: white;
    font-weight: 400;
    text-decoration: none;
  }
  li {
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
  }
`

function TempNav() {
  const [open, setOpen] = useState(false)
  const router = useRouter()
  useEffect(() => {
    setOpen(false)
  }, [router.pathname])
  return (
    <>
      <Navi onMouseOver={() => setOpen((state) => !state)}>
        <BurgerImage src={BurgerIcon} />
      </Navi>
      <NaviShow open={open}>
        <li>
          <Link href="/">
            <a>Dashboard</a>
          </Link>
        </li>
        <li>
          <Link href="/signup">
            <a>Sign up</a>
          </Link>
        </li>
        <li>
          <Link href="/signup-form">
            <a>Register</a>
          </Link>
        </li>
        <li>
          <Link href="/customize">
            <a>Customize</a>
          </Link>
        </li>
        <li>
          <Link href="/subscription">
            <a>Subscription</a>
          </Link>
        </li>
        <li>
          <Link href="/experience">
            <a>Experience Level</a>
          </Link>
        </li>
      </NaviShow>
    </>
  )
}

export default TempNav
