import React, { useRef, useEffect, useCallback, useMemo, useState } from 'react'
import useUserData from 'hooks/useUserData'

const authEndpoint = 'https://accounts.spotify.com/authorize'
const clientId = '1a10eccdd2714bde99fac090523e7317'

const redirectUri = 'https://how2beast-11.now.sh/workout'
const scopes = [
  'user-top-read',
  'user-read-currently-playing',
  'user-read-playback-state',
  'user-modify-playback-state',
  'user-read-private'
]

function getAccessTokenFromHash(hash) {
  const parsed = hash
    .substring(1)
    .split('&')
    .reduce(function (initial, item) {
      if (item) {
        var parts = item.split('=')
        initial[parts[0]] = decodeURIComponent(parts[1])
      }
      return initial
    }, {})
  return parsed
}

const Context = React.createContext()
export default Context

export function SpotifyProvider({ children }) {
  const userData = useUserData()
  const token = useRef()
  const [state, setState] = useState()
  const [profile, setProfile] = useState()
  const login = useCallback(async (reauth) => {
    if (!window?.cordova?.InAppBrowser?.open) {
      return Promise.resolve
    }
    const ref = window.cordova.InAppBrowser.open(
      `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
        '%20'
      )}&response_type=token${reauth ? '&show_dialog=true' : ''}`,
      '_blank',
      'beforeload=yes,hidden=yes'
    )
    ref.addEventListener('loadstop', () => {
      setTimeout(() => ref.show(), 100)
    })

    return await new Promise((resolve) =>
      ref.addEventListener('beforeload', async (evt, cb) => {
        console.log(evt.url)
        const data = getAccessTokenFromHash(new URL(evt.url).hash)
        console.log(data)
        if (data.access_token) {
          ref.close()
          token.current = {
            token: data.access_token,
            expires: Date.now() + data.expires_in * 1000
          }
          await fetch(`https://api.spotify.com/v1/me`, {
            method: 'GET',
            headers: {
              Authorization: 'Bearer ' + token.current.token
            }
          })
            .then((res) => res.json())
            .then(setProfile)
          resolve(data)
        } else {
          cb(evt.url)
        }
      })
    )
  }, [])
  const callApi = useCallback(
    async (method, endpoint) => {
      if (!(token.current?.expires > Date.now())) {
        await login()
      }
      console.log(token.current)
      let result
      if (method && endpoint)
        result = await fetch(`https://api.spotify.com/v1/me/${endpoint}`, {
          method,
          headers: {
            Authorization: 'Bearer ' + token.current.token
          }
        })
          .then((r) => r.json())
          .catch((error) => ({
            error
          }))
      return await fetch(`https://api.spotify.com/v1/me/player`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token.current.token
        }
      })
        .then((r) => r.json())
        .then((r) => {
          setState(r)
          return result || r
        })
        .catch((error) => console.log(error) || result || { error })
    },
    [login, userData, setState]
  )

  useEffect(() => {
    window.callApi = callApi
  }, [callApi])

  const startListen = useCallback(() => {
    callApi()
    const interval = setInterval(() => callApi(), 5000)
    return () => {
      clearInterval(interval)
    }
  }, [callApi])

  const value = useMemo(
    () => ({ login, state, callApi, startListen, profile }),
    [callApi, startListen, state, login, profile]
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}
