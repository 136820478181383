import React, { useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import Router from 'next/router'
import useUserData from 'hooks/useUserData'
import { useCollection } from '@sb-konzept/firebase-hooks'
import moment from 'moment'
import Close from 'images/close.png'

const Context = React.createContext()
export default Context

const Backdrop = styled.div`
  position: relative;
  z-index: 1002;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: ${({ open }) => (open ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
`

const Modal = styled.div`
  position: relative;
  background-color: #101010;
  width: 80%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #939393;
`

const CloseButton = styled.div`
  position: absolute;
  z-index: 1000;
  width: 1.5rem;
  height: 1.5rem;
  right: 10px;
  top: 10px;
  border-radius: 50%;
`

const MealButton = styled.button`
  font-size: 0.938rem;
  font-weight: 700;
  color: white;
  border: 0px;
  border-radius: 10px;
  width: 80%;
  height: 2.813rem;
  background: #2c2c2c;
  margin: 0.5rem;
`

export function PhotoProvider({ children }) {
  const [state, setState] = useState({ cb: null })

  const takeCordovaPhoto = useCallback(options => {
    return new Promise((resolve, reject) => {
      return navigator.camera.getPicture(resolve, reject, {
        targetWidth: 400,
        targetHeight: 400,
        cameraDirection: 1,
        quality: 100,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        ...options
      })
    })
      .catch(console.log)
      .then(x => {
        StatusBar.overlaysWebView(false)
        StatusBar.overlaysWebView(true)
        return x
      })
  }, [])

  const takePhoto = useCallback(options => {
    console.log(options)
    return new Promise(resolve =>
      setState({
        cb: sourceType => {
          setState({ cb: null })
          resolve(takeCordovaPhoto({ ...options, sourceType }))
        }
      })
    )
  })
  console.log(state.cb)
  const val = useMemo(() => ({ takePhoto }), [takePhoto])

  return (
    <>
      {state.cb && (
        <Backdrop>
          <Modal>
            <CloseButton onClick={() => setState({ cb: null })}>
              <img
                src={Close}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </CloseButton>

            <MealButton onClick={() => state.cb(1)}>TAKE PHOTO</MealButton>
            <MealButton onClick={() => state.cb(0)}>LIBRARY</MealButton>
          </Modal>
        </Backdrop>
      )}
      <Context.Provider value={val}>{children}</Context.Provider>
    </>
  )
}
